@import "src/themes/mixins";

.article-page {
    display: flex;
    flex-direction: column;
    margin: var(--spacing) 0;
    --spacing: 50px;
    margin: 0 auto;

    > * {
        margin: 20px 0;
    }

    &.small {
      max-width: 1180px;
    }

    &.large {
      max-width: 1420px;
    }

    &.center {
        text-align: center;
    }

    &.left {
        text-align: left;
    }

    &.right {
        text-align: right;
    }
}

.inverted {
    background-color: map-get($colors, 'greys', 'dark');
}