@import "src/themes/variables.module";

.faq-box {
    height: 100%;
    max-width: 700px;
    width: 100%;
    margin: -20px 0;

    > :global(.accordion) {
        margin: 20px 0;
    }

    .faq-item-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 -10px;
        position: relative;

        .faq-item-description-wrapper {
            margin: 0 10px;
            flex: 2;
            display: flex;
            justify-content: center;
            align-items: center;

            .faq-item-description {
                margin-right: 18px;
                font-size: 14px;
                line-height: 22px;
            }
        }

        .faq-item-image {
            margin: 0 10px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 240px;
        }

        @media (max-width: #{map-get($breakpoints, mobile)}) {
            flex-direction: column;

            > .faq-item-image {
                align-self: center;
                width: 100%;
            }
        }
    }

    .faq-description {
        margin-bottom: 25px;
    }
}
