@import "src/themes/mixins";

.head-image-with-text-wrapper {
    display: flex;
    justify-content: space-between;

    .image-wrapper {
        width: 48%;
        img {
            height: 677px;
        }
    }

    .text-container {
        width: 48%;
    }

    &.left {
        .image-wrapper {
            order: 1;
            @media (max-width: #{map-get($breakpoints, mobile)}) {
                margin-bottom: 20px;
            }
        }
        .text-container {
            order: 2;
        }
    }

    &.right {
        .image-wrapper {
            order: 2;
            @media (max-width: #{map-get($breakpoints, mobile)}) {
                margin-top: 20px;
            }
            .text-container {
                order: 1;
            }
        }
    }

    @media (max-width: #{map-get($breakpoints, mobile)}) {
        flex-direction: column;

        .image-wrapper,
        .text-container {
            width: 100%;
        }
    }
}
