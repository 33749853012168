@import 'src/themes/mixins';

.quote-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 50px;

    .author-image {
        width: 100px;
        height: 100px;
        margin-bottom: 25px;
    }

    .quote-wrapper {
        display: flex;
        position: relative;

        .quote::before {
            position: absolute;
            bottom: 0;
            left: -55px;
            content: open-quote;
            font-size: 85px;
            font-weight: 900;
            color: map-get($colors, 'greys', 'dark');
        }

        .quote::after {
            position: absolute;
            top: -10px;
            right: -60px;
            content: close-quote;
            font-size: 85px;
            font-weight: 900;
            color: map-get($colors, 'greys', 'dark');
        }
    
        .quote.inverted::before {
            color: map-get($colors, 'greys', 'light');
        }

        .quote.inverted::after {
            color: map-get($colors, 'greys', 'light');
        }
        
    }

    .author-name {
        padding-top: 34px;
    }

    .author-title {
        padding-top: 8px;
    }
}

.quote-section.left {
    align-items: flex-start;
}

.quote-section.center {
    align-items: center;
}

.quote-section.right {
    align-items: flex-end;
}