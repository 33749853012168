@import "src/themes/variables.module";

.article-page-newsletter {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  height: 400px;
  position: relative;

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: #{map-get($breakpoints, desktopSmall)}) {
      padding-right: 16px;
      padding-left: 16px;
    }

    .newsletter-headline {
      margin-top: 90px;
      margin-bottom: 40px;
      max-width: 1420px;
    }

    .newsletter-input-wrapper {
      width: 30%;
      margin-bottom: 90px;
      max-width: 1420px;
      display: flex;
      justify-content: center;

      .newsletter-input {
        width: 100%;
        background-color: transparent;
      }
    }


    @media (max-width: #{map-get($breakpoints, desktopSmall)}) {
      .newsletter-input-wrapper {
        width: 100%;
      }
    }
  }
}
