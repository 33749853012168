@import "src/themes/variables.module";

.text-image-teaser {
    position: relative;
    display: flex;
    align-items: flex-start;
    max-width: 1420px;
    margin-bottom: 5%;

    .right-wrapper,
    .left-wrapper {
        display: flex;
        flex-direction: column;
        width: 42%;
        justify-content: center;
    }

    .right-wrapper-img,
    .left-wrapper-img {
        width: 50%;
    }

    .left-wrapper,
    .left-wrapper-img {
        text-align: right;
        order: 1;
        margin-right: 8%;
    }

    .left-wrapper {
        .buttons {
            width: 100%;
            justify-content: flex-end;

            a {
                text-decoration: none !important;
            }

            > button {
                margin: unset !important;
            }

            a:nth-of-type(2) {
                margin-left: 30px !important;
            }
        }
    }

    .right-wrapper {
        .buttons {
            width: 100%;
            justify-content: flex-start;

            a {
                text-decoration: none !important;
            }

            > button {
                margin: unset !important;
            }

            a:first-of-type {
                margin-right: 30px;
            }
        }
    }

    .right-wrapper,
    .right-wrapper-img {
        text-align: left;
        order: 2;
    }

    .buttons {
        padding-top: 16px;
        display: flex;
        width: 100%;

        .button-1 {
            margin-right: 50px;
            order: 1;
        }

        .button-2 {
            margin-left: 50px;
            order: 2;
        }
    }

    &.overTheEdge {
        .left-wrapper-img {
            margin-left: -18%;
        }

        .right-wrapper-img {
            margin-right: -18%;
        }

        .right-wrapper {
            width: 60%;
        }

        .left-wrapper {
            width: 60%;
        }
        @media (max-width: #{map-get($breakpoints, mobile)}) {
            .right-wrapper-img,
            .left-wrapper-img {
                width: 100%;
            }

            .right-wrapper,
            .left-wrapper {
                width: 100%;
            }
        }
    }

    @media (max-width: #{map-get($breakpoints, mobile)}) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
                
        max-height: none;
        margin-bottom: 10%;

        .right-wrapper-img,
        .left-wrapper-img {
            order: 1;
            width: 100%;
            margin-right: 0;
            margin-left: 0;
            margin-bottom: 30px;
        }

        .right-wrapper-img {
            margin-left: 0 !important;
            margin-right: 0 !important;;
        }

        .right-wrapper,
        .left-wrapper {
            order: 2;
            width: 100%;
            text-align: center;
            margin-right: 0;
            margin-left: 0;
        }

        .buttons {
            justify-content: center !important;
        }

        .buttons > a:first-of-type {
            margin-right: 0 !important;
        }

    }
}
