@import "src/themes/variables.module";

.text-only-teaser-wrapper {
    text-align: center;
    padding-bottom: 50px;

    .text {
        padding-top: 50px;
    }

    .footer {
        margin-top: #{map-get($spacings, "smallLarge")};
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-top: 32px;
    }
}

.text-only-teaser-wrapper-small {
    width: min(100%, 312px);
}

.text-only-teaser-wrapper-medium {
    width: min(100%, 418px);
}

.text-only-teaser-wrapper-large {
    width: min(100%, 626px);
}

.link {
    text-decoration: none !important;
}
