@import "src/themes/variables.module";

.podcast-teaser-card-wrapper {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;

    .teaser-card-text {
        position: relative;
    }

    .optional-info {
        position: absolute;
        right: 8px;
        top: -32px;
        font-size: 14px;
        line-height: 24px;
        color: map-get($colors, "greys", "middleDark");
        letter-spacing: 0.07px;
    }

    .label-wrapper {
        display: flex;
        margin-bottom: 10px;
        margin-top: 10px;

        .label-container {
            background-color: map-get($colors, "categoryColors", "art");
            transform: skewX(-25deg);
            padding: 4px 16px;
            color: map-get($colors, "greys", "light");
            margin-right: 10px;

            .label {
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.14px;
                transform: skewX(25deg);
            }
        }
    }

    &.podcast-teaser {
        margin-top: 0;
        width: 78%;
        position: relative;
        padding-top: 10px;
        display: flex;
        height: 100%;
        flex-direction: column;
        padding-right: 0;
        .teaser-card-text {
            padding-right: 6%;
        }
        @media (max-width: #{map-get($breakpoints, mobile)}) {
            width: 100%;
            .teaser-card-text {
                padding-right: 8%;
                padding-left: 8%;
            }
        }
    }
}
