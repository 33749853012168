@import "src/themes/variables.module";

.number-text-teaser {
  clip-path: polygon(100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%, 50% 0);
  height: 320px;
  width: 278px;
  max-height: 320px;
  max-width: 278px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #{map-get($colors, "greys", "darkLight")};

  span:first-of-type {
    color: #{map-get($colors, "primary", "middleDark")};
    margin-bottom: 34px;
  }

  span:last-of-type {
    color: #{map-get($colors, "greys", "light")};
  }
}
